html,
body {
  margin: 0;
  height: 100%;
  background-color: #282828;
}

h1 {
  text-align: center;
  font-size: 64px;
  color: #d79921;
}

#app {
  height: 95%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

pre {
  font-size: 13px;
  margin-top: 0;
  text-align: center;
  color: #79740e;
}
